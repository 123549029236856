import {cva} from 'class-variance-authority';

import Typography from '@/components/base/elements/Typography/Typography';
import {twMerge} from '@/stylesheets/twMerge';
import type {Mode} from '@/components/base/types';
import useSignup from '@/hooks/useSignup';
import useReducedMotion from '@/hooks/useReducedMotion';
import {useAcquisitionIncentive} from '@/hooks/useAcquisitionIncentive';
import {useIntlAcquisitionIncentive} from '@/hooks/experiments/useIntlAcquisitionIncentive';
import {useGmvRewardsAcquisitionIncentive} from '@/hooks/experiments/useGmvRewardsAcquisitionIncentive';
import useHandle from '@/hooks/useHandle';
import {useSiteData} from '@/hooks/useSiteData';

import {useTranslations} from '../Page/I18N';

interface IncentivesPromoBannerProps {
  mode?: Mode;
  align?: 'left' | 'center';
  className?: string;
}

const BannerStyles = cva(
  [
    'relative z-10 animate-incentives-fill-background px-2 py-1 font-normal text-body-base rounded align-top leading-[2rem] box-decoration-clone [&_a]:!font-normal [&_a]:!text-inherit',
  ],
  {
    variants: {
      mode: {
        dark: 'text-black bg-gradient-to-r bg-right-top from-[#075E3426_50%] to-[transparent_50%] mix-blend-hard-light bg-[length:200%_100%]',
        darkReducedMotion: 'text-black bg-[#075E3426]',
        light:
          'text-white bg-gradient-to-r bg-right-top from-[#CFA1FC33_50%] to-[transparent_50%] mix-blend-hard-light bg-[length:200%_100%]',
        lightReducedMotion: 'text-white bg-gradient-to-r bg-[#CFA1FC33]',
      },
      type: {
        double: 'first:[&_a]:!no-underline',
        single: '',
      },
    },
  },
);

function IncentivesPromoBannerContent({
  mode,
  align = 'left',
  className,
}: IncentivesPromoBannerProps) {
  const {t} = useTranslations();

  const {signupUrl} = useSignup();
  const {handle} = useSiteData();
  const prefersReducedMotion = useReducedMotion();
  const bannerMode = prefersReducedMotion
    ? ((mode + 'ReducedMotion') as Mode)
    : mode;

  const {active: usDesktopActive, termsLink: usDesktopTerms} =
    useAcquisitionIncentive();
  const {
    active: intlAcqActive,
    learnMore,
    creditAmount,
  } = useIntlAcquisitionIncentive();
  const {
    isTreatment: gmvRewardsActive,
    learnMore: gmvRewardsLearnMoreLink,
    totalCap: gmvRewardsIncentiveMaxPayoutCap,
  } = useGmvRewardsAcquisitionIncentive();

  const renderIntlAcq = intlAcqActive && handle !== 'free-trial';
  const renderGmvRewards = gmvRewardsActive && handle !== 'free-trial';
  const bannerType = renderIntlAcq ? 'single' : 'double';
  const getBannerContent = () => {
    if (renderIntlAcq) {
      return t(`global:incentivesPromoBanner.international_acquisition`, {
        learnMore: learnMore,
        creditAmount: creditAmount,
      });
    }
    if (usDesktopActive) {
      return t(`global:incentivesPromoBanner.app_store_credit`, {
        incentiveTerms: usDesktopTerms,
        signupUrl,
      });
    }
    if (renderGmvRewards) {
      return t(`global:incentivesPromoBanner.gmv_rewards`, {
        learnMore: gmvRewardsLearnMoreLink,
        maxPayoutCap: gmvRewardsIncentiveMaxPayoutCap,
        signupUrl: signupUrl,
      });
    }
  };

  const bannerContent = getBannerContent();

  if (!bannerContent) return null;

  return (
    <div
      className={twMerge(
        'items-center relative overflow-hidden max-w-[95%]',
        className,
        align === 'center' && 'text-center mx-auto',
      )}
    >
      <Typography
        size="body-base"
        className={BannerStyles({mode: bannerMode, type: bannerType})}
        as="span"
      >
        {bannerContent}
      </Typography>
    </div>
  );
}

export default function IncentivesPromoBanner(
  props: IncentivesPromoBannerProps,
) {
  const {incentivePromo} = useHandle();

  return incentivePromo && <IncentivesPromoBannerContent {...props} />;
}
